import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface User {
  [x: string]: any;
}

interface UserData {
  first_name: string;
  last_name: string;
  profile_pic: File | null;
  profile_pic_horizontal: File | null;
  profile_pic_round: File | null;
  accessable_modules: any;
}

export interface UserState {
  isLoggedIn: boolean;
  user: User | null;
  subdomain: string | null;
}

const initialUserState: UserState = {
  isLoggedIn: false,
  user: null,
  subdomain: null,
};

// const generateSessionId = () => {
//   return Math.random().toString(36).substring(2, 15);
// };

const userSlice = createSlice({
  name: 'auth',
  initialState: initialUserState,
  reducers: {
    setDetail: (state, action: PayloadAction<User | null>) => {
      if (action.payload) {
        const currentSubdomain = window.location.hostname.split('.')[0];
        // const storedSubdomain = localStorage.getItem('currentSubdomain');
        // const sessionId = sessionStorage.getItem('sessionId');
        
        // if (storedSubdomain && storedSubdomain !== currentSubdomain) {
        //   // User is trying to log in to a different subdomain
        //   alert('You are already logged in to another subdomain. Please log out first.');
        //   return state;
        // }

        // if (!sessionId) {
        //   const newSessionId = generateSessionId();
        //   sessionStorage.setItem('sessionId', newSessionId);
        //   localStorage.setItem('currentSubdomain', currentSubdomain);
        //   localStorage.setItem('currentSessionId', newSessionId);
        // }

        state.isLoggedIn = true;
        state.user = action.payload;
        state.subdomain = currentSubdomain;
      }
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            profile_pic: action.payload.profile_pic,
            profile_pic_horizontal:action.payload.profile_pic_horizontal,
            profile_pic_round:action.payload.profile_pic_round,
            accessable_modules: action.payload.accessable_modules
          },
        };
      } else {
        return state;
      }
    },
    logout: (state) => {
      localStorage.clear();
      // localStorage.removeItem('currentSubdomain');
      // localStorage.removeItem('currentSessionId');
      // sessionStorage.removeItem('sessionId');
      // loginChannel.postMessage({ type: 'LOGOUT' });
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        subdomain: null,
      };
    },
    checkSession: (state) => {
      const storedSubdomain = localStorage.getItem('currentSubdomain');
      const storedSessionId = localStorage.getItem('currentSessionId');
      const currentSessionId = sessionStorage.getItem('sessionId');

      if (storedSubdomain && storedSessionId && storedSessionId !== currentSessionId) {
        // Session mismatch, log out the user
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          subdomain: null,
        };
      }
      return state;
    },
  },
});

// Listen for login attempts from other tabs
// loginChannel.onmessage = (msg) => {
//   alert('You are already logged in to another subdomain. Please log out first.');
//   if (msg.type === 'LOGIN_ATTEMPT' && msg.subdomain !== window.location.hostname.split('.')[0]) {
//     alert('You are already logged in to another subdomain. Please log out first.');
//     // Optionally, you can force logout here
//     userSlice.actions.logout();
//   } else if (msg.type === 'LOGOUT') {
//     userSlice.actions.logout();
//   }
// };

export const { setDetail, setUserData, logout, checkSession } = userSlice.actions;
export default userSlice.reducer;