import React, { useEffect, useState } from "react";
import TestingTemplate from "./TestingTemplate";
import Breadcrumbs from "../BreadCrumbs";
import MathTypeEditor from "./QuestionEditor";
import TestingEquation from "./Equation";
import Editor from "./Lexical/Editor";
import { CustomEditor } from "./DirectSelection/customEditor";
import FroalaEditorComponent from "./Florala";


export const TestingEditors: React.FC = () => {
  const [activeButton, setActiveButton] = useState("1");
  const handleTabChange = (type: string) => {
    setActiveButton(type);
  };
  const [htmlContent, setHtmlContent] = useState("");

  // const [editorData, setEditorData] = useState(
  //   localStorage.getItem("editorData") || ""
  // );
  // const handleEditorContent = (data: string) => {
  //   setEditorData(data);
  //   localStorage.setItem("editorData", data);
  // };

  const breadCrumbItems = [
    { label: "Home", path: `/dashboard` },
    "Question Bank",
    "Testing Editors",
  ];
  const [FroalaValue, setFroalaValue] = useState(
    `<p id="isPasted">Compute the definite integral:&nbsp;<math xmlns="http://www.w3.org/1998/Math/MathML"><msubsup><mo>&#8747;</mo><mn>1</mn><mn>4</mn></msubsup><mfenced><mrow><mn>2</mn><msup><mi>x</mi><mn>3</mn></msup><mo>+</mo><mi>x</mi><mo>+</mo><mn>1</mn></mrow></mfenced><mi>d</mi><mi>x</mi></math>&nbsp;</p>`
  );

  useEffect(()=>{
  },[FroalaValue])

  const renderComponent = () => {
    switch (activeButton) {
      case "1":
        return (
          <div className="w-screen h-screen pt-6 pb-12 bg-GreatifyNeutral-200  overflow-auto">
            <FroalaEditorComponent value={FroalaValue} setValue={setFroalaValue} />
          </div>
        );
      case "2":
        return (
          <div className="w-screen h-screen pt-6 pb-12   bg-GreatifyNeutral-200  overflow-auto">
            <TestingTemplate />
          </div>
        );
      case "3":
        return (
          <div className="w-screen h-screen pt-6 pb-12   bg-GreatifyNeutral-200  overflow-auto">
            <TestingEquation />
          </div>
        );
      case "4":
        return (
          <div className="w-screen h-screen pt-6 pb-12   bg-GreatifyNeutral-200  overflow-auto">
            <CustomEditor />
          </div>
        );
      case "5":
        return (
          <div className="w-screen h-screen pt-6 pb-12   bg-GreatifyNeutral-200  overflow-auto">
              <Editor onHTMLChange={(html: string) => setHtmlContent(html)} />
          </div>
        );
      case "6":
        return (
          <div className="w-screen h-screen pt-6 pb-12   bg-GreatifyNeutral-200  overflow-auto">
            <MathTypeEditor />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-GreatifyNeutral-200">
      <div className="flex gap-4 bg-white items-center justify-center font-medium border-b border-GreatifyNeutral-200 ">
        <button
          className={`px-4 py-3 select-none ${
            activeButton === "1"
              ? "text-GreatifyChalkGreen-500 border-b-2 border-GreatifyOrange-500"
              : "text-GreatifyNeutral-400"
          }`}
          onClick={() => handleTabChange("1")}
        >
          Froala Editor
        </button>
        <button
          className={`px-4 py-3 select-none ${
            activeButton === "2"
              ? "text-GreatifyChalkGreen-500 border-b-2 border-GreatifyOrange-500"
              : "text-GreatifyNeutral-400"
          }`}
          onClick={() => handleTabChange("2")}
        >
          Custom Editor
        </button>

        <button
          className={`px-4 py-3 select-none ${
            activeButton === "3"
              ? "text-GreatifyChalkGreen-500 border-b-2 border-GreatifyOrange-500"
              : "text-GreatifyNeutral-400"
          }`}
          onClick={() => handleTabChange("3")}
        >
          Equation Editor
        </button>

        <button
          className={`px-4 py-3 select-none ${
            activeButton === "4"
              ? "text-GreatifyChalkGreen-500 border-b-2 border-GreatifyOrange-500"
              : "text-GreatifyNeutral-400"
          }`}
          onClick={() => handleTabChange("4")}
        >
          Direct Selection
        </button>

        <button
          className={`px-4 py-3 select-none ${
            activeButton === "5"
              ? "text-GreatifyChalkGreen-500 border-b-2 border-GreatifyOrange-500"
              : "text-GreatifyNeutral-400"
          }`}
          onClick={() => handleTabChange("5")}
        >
          Lexical Editor
        </button>

        <button
          className={`px-4 py-3 select-none ${
            activeButton === "6"
              ? "text-GreatifyChalkGreen-500 border-b-2 border-GreatifyOrange-500"
              : "text-GreatifyNeutral-400"
          }`}
          onClick={() => handleTabChange("6")}
        >
          Equation Editor
        </button>
      </div>
      <div className="w-[70%] mx-auto py-4">
        <Breadcrumbs items={breadCrumbItems} />
      </div>

      {renderComponent()}
    </div>
  );
};
