import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import Vector from "./../../assets/modalIllustration.png";

import { XMarkIcon } from "@heroicons/react/16/solid";
import { Loader } from "./../../components/loaders/loader";

interface modalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalTitle: string;
  button1name?: string;
  button2name?: string;
  onModalSubmit: () => void;
  onModalCancel?: () => void;
  customWidth?: string;
  loading?: boolean;
  loadingText?: string | string[];
  hideButton1?: boolean;
  hideButton2?: boolean;
  hideBothButton?: boolean;
  hideSliderHeader?: boolean;
  customPadding?: string;
  closeOnOutsideClick?: boolean;
}

const SlideOver = ({
  open,
  onClose,
  children,
  modalTitle,
  onModalSubmit,
  button1name,
  button2name,
  customWidth,
  loading,
  loadingText,
  hideButton1 = false,
  hideButton2 = false,
  hideBothButton = false,
  hideSliderHeader = false,
  customPadding,
  onModalCancel,
  closeOnOutsideClick = false,
  
}: modalProps) => {
  const handleModalSubmit = () => {
    onModalSubmit();
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog className="relative z-10" onClose={onModalCancel || onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden z-30">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel
                  className={`relative pointer-events-auto overflow-hidden ${
                    customWidth ? customWidth : "w-[650px]"
                  } bg-white progressScroll shadow-md`}
                >
                {!hideSliderHeader &&  <div className="flex justify-between sticky h-[60px] w-full top-0 left-0 bg-white z-20 py-4 px-8 border-b border-GreatifyNeutral-200">
                    <DialogTitle
                      as="h3"
                      className="text-xl font-semibold leading-6 text-GreatifyChalkGreen-500"
                    >
                      {modalTitle ? modalTitle : "Modal"}
                    </DialogTitle>
                    <div
                      onClick={onClose}
                      className="z-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border bg-white shadow-sm"
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </div>

                    <img
                      src={Vector}
                      className="z-1 absolute top-0 right-0 h-full"
                      alt="Illustration"
                    />
                  </div> }

                  <div className={`relative   w-full ${ hideBothButton && hideSliderHeader ? "h-full" : hideSliderHeader ? "h-[calc(100%-70px)]" : hideBothButton ?  "h-[calc(100%-70px)]" : "h-[calc(100%-140px)]" }  `}>
                    {loading && (
                      <Loader
                        text={loadingText}
                        variant="modal"
                        customStyles="w-[70%] py-8 px-4 shadow-xl"
                        className="absolute top-0 left-0 z-20 bg-GreatifyGreen-500/10 "
                      />
                    )}
                    <div className={`${loading ? "blur-[2px]" : ""} w-full  h-full overflow-auto customScroll ${customPadding ? customPadding : "px-8 py-4  "} `}>
                      {children}
                    </div>
                  </div>

                  {!hideBothButton && (
                    <div className=" flex justify-between items-center sticky h-[80px] w-full bottom-0 left-0 bg-white z-10 py-4 px-8 border-t border-GreatifyNeutral-200">
                      {!hideButton1 && (
                  <button
                  id="btn-secondary"
                  onClick={onModalCancel || onClose}
                  className="cursor-pointer py-2 h-fit px-4 bg-white text-[#00373A] border border-GreatifyNeutral-200 flex justify-center items-center rounded-md text-lg font-semibold"
                >
                  {button2name || "Cancel"}
                </button>
                      )}
                      {!hideButton2 && (
                        <button
                          id="btn-primary"
                          onClick={handleModalSubmit}
                          className="cursor-pointer h-fit greenGradient border border-GreatifyGreen-600 py-2  px-4 flex justify-center items-center  text-lg font-semibold rounded-md text-white "
                        >
                          {button1name ? button1name : "Submit"}
                        </button>
                      )}
                    </div>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SlideOver;
