import React, { useState, useEffect } from "react";
import FroalaEditor from "react-froala-wysiwyg";
// import FroalaEditorView from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import $ from "jquery";

declare global {
    interface Window {
      $: typeof import("jquery");
      jQuery: typeof import("jquery");
      FroalaEditor: any;
    }
}

const FroalaEditorComponent = ({value, setValue, labelName}: any) => {
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [isInitialized, setIsInitialized] = useState(false);


  useEffect(() => {
    window.$ = $;
    window.jQuery = $;

    const initializeMathType = async () => {
      try {
        const FroalaEditor = await import("froala-editor");
        window.FroalaEditor = FroalaEditor.default || FroalaEditor;

        const mathType = await import("@wiris/mathtype-froala3");
        if (
          mathType.default &&
          typeof mathType.default.integrateFroalaPlugin === "function"
        ) {
          mathType.default.integrateFroalaPlugin();
        }

        setIsInitialized(true);
      } catch (error) {
        console.error("Error initializing MathType:", error);
      }
    };

    initializeMathType();

    return () => {
      if (editorInstance) {
        editorInstance.destroy();
      }
    };
  }, []);

  // Handle the editor value change
  const handleEditorChange = (newValue: string) => {
    // Remove the prefix if it already exists to prevent duplication
    const cleanValue = newValue.replace(/^\$editorvalue/, '').trim();
    // Prepend $editorvalue to the clean value
    const finalValue = `$editorvalue${cleanValue}`;
    setValue(finalValue);
  };

  // Modified the model prop to remove the prefix for display
  const getDisplayValue = (value: string) => {
    return value?.replace(/^\$editorvalue/, '') || '';
  };

  const config = {
    toolbarButtons: {
      moreMisc: {
        buttons: ["undo", "redo"],
        buttonsVisible: 2,
      },
      moreText: {
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "formatOLSimple",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
        buttonsVisible: 4,
      },
      moreRich: {
        buttons: [
          "wirisEditor",
          "wirisChemistry",
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
        ],
        buttonsVisible: 2,
      },
    },

    toolbarButtonsXS: {
      moreMisc: {
        buttons: ["undo", "redo"],
        buttonsVisible: 0,
      },
      moreText: {
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "formatOLSimple",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
        buttonsVisible: 0,
      },
      moreRich: {
        buttons: [
          "wirisEditor",
          "wirisChemistry",
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
        ],
        buttonsVisible: 0,
      },
    },

    pluginsEnabled: [
      "wiris",
      "table",
      "lists",
      "codeView",
      "link",
      "image",
      "imageManager",
      "video",
      "colors",
      "paragraphFormat",
      "paragraphStyle",
      "align",
      "quote",
    ],

    height: 300,
    heightMin: 300,
    heightMax: 300,

    iframe: false,
    iframeStyle: `
      html {
        height: 300px;
        overflow: auto;
      }
      body {
        height: 100%;
        padding: 10px;
        background-color: white;
        color: black;
        font-family: Arial, sans-serif;
        font-size: 14px;
        line-height: 1.6;
        overflow: auto;
        box-sizing: border-box;
      }
      .fr-view {
        min-height: calc(300px - 20px);
      }
    `,

    tableStyles: {
      "no-border": "No border",
      bordered: "Bordered",
      striped: "Striped",
    },
    tableEditButtons: [
      "tableHeader",
      "tableRemove",
      "tableRows",
      "tableColumns",
      "tableStyle",
      "tableCells",
      "tableCellBackground",
      "tableCellVerticalAlign",
      "tableCellHorizontalAlign",
    ],

    imageAllowedTypes: ["jpeg", "jpg", "png", "gif", "webp"],
    imageDefaultWidth: 300,
    imageDefaultDisplay: "inline",
    imageEditButtons: [
      "imageReplace",
      "imageAlign",
      "imageRemove",
      "imageSize",
      "imageAlt",
    ],

    linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
    linkAttributes: {
      target: "_blank",
    },

    pastePlain: true,
    htmlAllowedTags: [".*"],
    htmlAllowedAttrs: [".*"],
    htmlRemoveTags: ["script"],
    useClasses: false,
    language: "en",

    events: {
      initialized: function(editor: any) {
        setEditorInstance(editor);
      },
      contentChanged: function() {
        if (editorInstance) {
          editorInstance.size.refresh();
        }
      },
    },

    wiris: {
      mathTypeParameters: {
        editorParameters: { language: "en" },
      },
      chemTypeParameters: {
        editorParameters: { language: "en" },
      },
    },
  };

  if (!isInitialized) {
    return <div>Loading editor...</div>;
  }

  return (
    <div className="main-editor-container w-full mx-auto">
      {labelName && (
        <p className="text-xs text-GreatifyNeutral-500 font-medium uppercase mb-2 flex justify-start items-start">
          {labelName}
        </p>
      )}
      <div className="editor-wrapper">
        <FroalaEditor
          tag="textarea"
          model={getDisplayValue(value)}
          config={config}
          onModelChange={handleEditorChange}
        />
      </div>
    </div>
  );
};

export default FroalaEditorComponent;